<template>
  <TTView>
    <VRow>
      <VCol>
        <SkillsTable
          :loading="loading"
          :skills="skills"
        >
          <template #topActions>
            <VCol
              class="d-flex pa-0"
              cols="9"
            >
              <TTTextField
                v-model="searchString"
                append-icon="fal fa-search"
                class="search tt-light-mono-100--text"
                placeholder="Поиск"
              />

              <VBtn
                :to="{
                  name : Names.R_COMPETENCY_MATRIX_SKILL_CREATE
                }"
                class="ml-3"
                color="primary"
              >
                <VIcon left>
                  fal fa-plus
                </VIcon>

                Новый навык
              </VBtn>
            </VCol>
          </template>

          <template #rowActions="{ item }">
            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  class="mx-1"
                  color="blue"
                  dark
                  exact
                  icon
                  small
                  v-bind="attrs"
                  @click="sendPush(item)"
                  v-on="on"
                >
                  <VIcon small>
                    fal fa-comments
                  </VIcon>
                </VBtn>
              </template>

              <span>Отправить push уведомление сотрудникам</span>
            </VTooltip>
            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  :to="{
                    name : Names.R_COMPETENCY_MATRIX_SKILL,
                    params : {
                      skillId : item.id
                    }
                  }"
                  class="mx-1"
                  color="blue"
                  dark
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <VIcon small>
                    fal fa-eye
                  </VIcon>
                </VBtn>
              </template>

              <span>Просмотр</span>
            </VTooltip>

            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  :to="{
                    name : Names.R_COMPETENCY_MATRIX_SKILL_EDIT,
                    params : {
                      skillId : item.id
                    }
                  }"
                  class="mx-1"
                  color="orange"
                  dark
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <VIcon small>
                    fal fa-edit
                  </VIcon>
                </VBtn>
              </template>

              <span>Редактирование</span>
            </VTooltip>

            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  class="mx-1"
                  color="red"
                  dark
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="deleteSkill(item)"
                >
                  <VIcon small>
                    fal fa-trash
                  </VIcon>
                </VBtn>
              </template>

              <span>Удаление</span>
            </VTooltip>
          </template>
        </SkillsTable>
        <VDialog
          v-model="confirmDialog"
          max-width="500px"
          @click:outside="handleCancel"
        >
          <VCard>
            <VCardTitle class="headline">
              Удалить навык
            </VCardTitle>

            <VCardText>
              Навык <span class="red--text">{{ skill.name }}</span> будет удален! <br>
              Действительно хотите удалить навык?
            </VCardText>

            <VCardActions>
              <VSpacer />

              <VBtn
                text
                @click="handleCancel"
              >
                Нет
              </VBtn>

              <VBtn
                color="red darken-1"
                text
                @click="handleDelete"
              >
                Да
              </VBtn>
            </VCardActions>
          </VCard>
        </VDialog>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { DEFAULT_SKILL } from '../../components/competency-matrix/skills/common';
import SkillsTable from '../../components/competency-matrix/skills/SkillsTable.vue';

export default {
  name: 'Skills',

  components: {
    SkillsTable,
  },

  inject: ['Names'],

  data() {
    return {
      skill: { ...DEFAULT_SKILL },
      skills: [],
      confirmDialog: false,
      loading: false,
      searchString: '',
      lastSearchString: null,
    };
  },

  watch: {
    searchString() {
      if (this.lastSearchString !== this.searchString) {
        this.fetchSkills();
      }
    },
  },

  async created() {
    await this.fetchSkills();
  },

  methods: {
    async fetchSkills() {
      this.lastSearchString = this.searchString;
      this.loading = true;
      try {
        const { skills } = await this.$di.api.CompetencyMatrix.SkillsSearch(
          { query: this.searchString },
        );
        this.skills = skills;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    resetSkillEdit() {
      this.confirmDialog = false;
      this.skill = { ...DEFAULT_SKILL };
    },

    handleCancel() {
      this.resetSkillEdit();
    },

    async handleDelete() {
      try {
        this.loading = true;
        const { id } = this.skill;
        const data = { id };

        await this.$di.api.CompetencyMatrix.SkillsDelete(data);

        this.resetSkillEdit();
        await this.fetchSkills();
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
        await this.fetchSkills();
      }
    },

    deleteSkill(item) {
      this.confirmDialog = true;
      this.skill = { ...item };
    },

    async sendPush(item) {
      const payload = {
        skillId: item.id,
      };

      try {
        await this.$di.api.Sysadmin.pushSendSkill(payload);
        this.$di.notify.snackSuccess('Уведомление успешно отправлено');
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.search {
  width: 100%;

  ::v-deep .v-icon {
    color: map-get($tt-light-mono-100, 'base') !important;
    caret-color: map-get($tt-light-mono-100, 'base') !important;
  }
}
</style>
